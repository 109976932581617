<template>
  <div>
    <head-layout :head-btn-options="headBtnOptions"
                 head-title="机构分组新增"
                 @head-save="headSave()"
                 @head-save-back="headSave(true)"
                 @head-cancel="headCancel"></head-layout>

    <div class="newZtFormBox" style="background-color: white; padding-top:10px;padding-right: 10px;">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="分组名称:" prop="groupName">
              <el-input v-model="form.groupName" placeholder="分组名称" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="分组编码:" prop="groupCode">
              <el-input v-model="form.groupCode" placeholder="分组编码" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="绑定单位:" prop="deptIdList">
              <!--              <el-tree-->
              <!--                ref="deptTree"-->
              <!--                node-key="id"-->
              <!--                show-checkbox-->
              <!--                :data="deptTreeData"-->
              <!--                :default-expand-all="true"-->
              <!--                :default-checked-keys="defaultChecked"-->
              <!--                :props="defaultProps"-->
              <!--                @check-change="handleCheckChange"-->
              <!--              >-->
              <!--              </el-tree>-->
              <avue-input-tree multiple v-model="form.deptIdList" placeholder="请填写绑定单位 "
                               :dic="deptTreeData" :props="inputTreeProps" ></avue-input-tree>
            </el-form-item>
          </el-col>

          <!--          <el-col :span="8">-->
          <!--            <el-button type="primary" @click="opendg" v-if="$route.query.id">绑定单位</el-button>-->
          <!--          </el-col>-->
        </el-row>
      </el-form>

      <el-dialog title="绑定单位" :visible.sync="dialogFormVisible">
        <el-tree :data="companyList" show-checkbox default-expand-all node-key="id" ref="tree" highlight-current
                 :props="defaultProps">
        </el-tree>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="SureBind">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import FormLayout from "@/views/components/layout/form-layout";
import {
  DeptGroupDetail as getDetail,
  SaveOrUpdateDeptGroup,
  trainingDeptGroupBindingDept
} from "@/api/safe";
import {getDeptTree} from "@/api/system/dept";

export default {
  name: "deptGroupAdd",
  computed: {},
  components: {
    FormLayout,
  },
  data() {
    return {
      type: "",
      inputTreeProps: {
        label: "title",
        value: "id",
      },
      form: {
        groupName: "",
        groupCode: "",
        deptIdList: [],
      },
      rules: {
        groupName: [
          {required: true, message: '请填写分组名称', trigger: "blur"},
        ],
        groupCode: [
          {required: true, message: '请填写分组编码', trigger: "blur"},
        ],
      },

      headBtnOptions: [
        {
          label: this.$t("cip.cmn.btn.saveBtn"),
          emit: "head-save",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.saveBackBtn"),
          emit: "head-save-back",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        },
      ],

      deptTreeData: [],
      defaultChecked: [],
      defaultProps: {
        label: "title",
        value: "id",
        key: "id",
      },
    };
  },
  watch: {
    form3: {
      handler() {
        this.form.deptIdList = this.$refs.tree.getHalfList();
      }
    }
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (["edit", "view"].includes(this.type)) {
      this.MDeptGroupDetail(id)
    }
    if (["view"].includes(this.type)) {
      this.setDisabled(this.deptTreeData)
    }
  },
  mounted() {
    this.getTreeData();
    this.form.deptIdList = this.$refs.tree.getHalfList();
  },
  methods: {

    // 获取部门数据
    getTreeData() {
      getDeptTree().then(res => {
        this.deptTreeData = res.data.data;
      });


      // getDeptTree().then((res) => {
      //   if (res.data.code == 200) {
      //     this.deptTreeData = res.data.data;
      //   }
      // });
    },

    // 禁止编辑状态
    setDisabled(obj) {
      obj.forEach((item) => {
        this.$set(item, "disabled", true);
        if (item.hasChildren) {
          this.setDisabled(item.children);
        }
      });
    },

    // 节点选中状态发生变化时的回调
    handleCheckChange() {
      let checkedKeys = this.$refs.deptTree.getCheckedKeys();
      this.form.deptIdList = checkedKeys;
    },

    opendg() {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        let checked = []
        this.form.groupRelationDtoList.forEach(element => {
          checked.push(element.deptId)
        });
        this.$refs.tree.setCheckedKeys(checked);
      })
    },

    SureBind() {
      let allChecked = this.$refs.tree.getCheckedNodes()
      this.form.groupRelationDtoList = []
      allChecked.forEach(element => {
        this.form.groupRelationDtoList.push({deptId: element.id, deptName: element.deptName})
      });
      var params = {
        id: this.$route.query.id,
        groupRelationDtoList: this.form.groupRelationDtoList
      }
      trainingDeptGroupBindingDept(params).then(() => {
        this.$message.success('绑定成功！')
        this.dialogFormVisible = false;
      })
    },

    handleChange() {
      this.bindList = []
      var nameList = []
      const checkedNodes = this.$refs.cascader.getCheckedNodes();
      nameList = checkedNodes.map(node => node.label);


      nameList.forEach((item1, index1) => {
        this.form.groupRelationDtoList.forEach((item2, index2) => {
          if (index1 === index2) {
            var aa = item2.length - 1
            this.bindList.push({deptId: item2[aa], deptName: item1})
          }
        });
      });
    },

    MDeptGroupDetail(id) {
      getDetail({id: id}).then((res) => {
        const {code, data} = res.data;
        if (code == 200) {
          this.form = data;
          this.defaultChecked = this.form.deptIdList;
          if (this.type == "view") {
            this.setDisabled(this.deptTreeData);
          }
        }
      });
    },

    // 保存和修改方法
    headSave(cancel = false) {
      console.log(this.form);
      if (this.form.deptIdList.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择部门",
        });
        return;
      }
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$loading();
          SaveOrUpdateDeptGroup(this.form).then((res) => {
            const {code, data} = res.data;
            if (code == 200) {
              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess'),
              });
            }
            if (cancel) {
              this.headCancel()
            } else {
              //刷新数据
              Object.assign(this.form, data)
              this.type = 'edit';
            }
            this.$loading().close();
          })
        }
      });
    },

    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    //扁平化 树
    flatten(arr) {
      let result = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
          result = result.concat(this.flatten(arr[i].children));
          arr[i].children = []
          result = result.concat(arr[i])
        } else {
          result.push(arr[i]);
        }
      }
      return result;
    },

    //寻找父级
    FinTfather(arr, fid) {
      let arr2 = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === fid) {
          if (arr[i].parentId !== "0") {
            fid = arr[i].parentId;
            arr2.push(arr[i].id);
            this.FinTfather(arr, fid);
          } else {
            arr2.push(arr[i].id);
          }
        }
      }
      return arr2;
    },
  },
};
</script>

<style scoped></style>
